:root {

    --bg-color-deep-blueblack: #030509;
    --bg-color-rich-mauve: #130419;
    --bg-color-rich-red: #2b060c;
    --bg-color-deep-blue: #04051e;
    --bg-color-royal-blue: #020220;
    --bg-color-aqua: #06222e;
}

.aboutMeBackground{
    position: absolute;
    display: flex;
    flex-direction: column;
    /* height: max(100%); */
    /* height: max(100vh,900px); */
    width: 100%;
    /* display: inline-block; */
    /* display: flex; */
    /* flex-direction: column ; */
    /* top: 0; */
    /* bottom: 0; */
    background-image: linear-gradient(var(--bg-color-rich-red),var(--bg-color-rich-mauve) 10%,var(--bg-color-deep-blueblack) 30%,var(--bg-color-deep-blueblack) 80%,var(--bg-color-deep-blue));
   /* margin: 0; */
   /* overflow: auto; */
    /* background-color: #2b060c; */
}
/* 
.projectsBackground{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(var(--bg-color-aqua) 0%,var(--bg-color-deep-blueblack) 30%,var(--bg-color-deep-blueblack) 80%,var(--bg-color-deep-blueblack));
    overflow: auto;
} */

.projectsBackground{
    position: absolute;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    overflow: auto;
    width: 100%;
    /* justify-content: center; */
    /* align-content: center; */
    /* align-items: center; */
    background-image: linear-gradient(var(--bg-color-aqua) 0%,var(--bg-color-deep-blueblack) 20%,var(--bg-color-deep-blueblack) 70%,var(--bg-color-deep-blueblack) 94%,var(--bg-color-royal-blue));
    
}

.portfolioBackground{
    overflow-x: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    
    /* background-image: linear-gradient(var(--bg-color-royal-blue) 10%,var(--bg-color-deep-blue) 15%,var(--bg-color-deep-blue) 80%,var(--bg-color-royal-blue)); */
}

.contactMeBackground{
    position: absolute;
    height: 100%;
    width: 100%;
    /* color: blue; */
    background-image: linear-gradient(var(--bg-color-royal-blue) 10%,var(--bg-color-deep-blueblack) 15%,var(--bg-color-deep-blueblack) 80%,var(--bg-color-royal-blue));
}

.homeBackground{
    position: absolute;
    /* transform:translateY(80%); */
    display: flex;
    flex-direction: row;
    justify-content: center;

    overflow-x: hidden;
    /* height: 100%; */
    width: 100%;
    transform: translateY(min(-5vh,-4vw));
    /* background-color: var( --div-video-edge-color) ; */
    color: var(--font-colour-content1);
    /* overflow: auto; */
}

.loadingBackground{
    position: absolute;
    z-index: 600;
    background-color: var(--div-loading-color);
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    /* animation: fadeout 4s; */
}

@keyframes fadeout {
    from {opacity: 1;}
    to {opacity: 0;}
}