.footerWrapper{
    position: relative;
    width: min(100%,1300px);
    justify-self: center;
    align-self: center;
}

.footer{
    position: relative;
    color: var(--font-colour-content1);
    font-size: 12px;
    /* padding: 10px; */
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    filter: brightness(70%);
}