.audioPlayerContainer{
    position: relative;
    display: flex;
    /* height: 100vh; */
    width: 100%;
    height: 88%;
    justify-content: center;
    align-items: center;
    /* align-items: center; */
    /* justify-content: center; */
  }

  .audioPlayerFrame{
    position: absolute; 
    /* top : 0; */
    width: 100%;
    height: 660px;
    /* max-height: 100%; */
    top : 50%;

    transform: translateY(-50%);
    /* top : max(80px); */
    color: var(--font-colour-content1);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    z-index: 3;
    justify-self: center;
    align-self: center;
  }

.aboutMeClickThroughContainer{
  padding-top: 30px;
  height: 40px;
  width: 100%;
  font-size: 25px;
}

.homeContentContainer{
  /* align-self: center; */
  width: 1300px;
  max-width: 90%;
  padding: max(5vh,2.5vw) 50px ;
  

}
.arrowDown{
  position: relative;
  color : var(--font-colour-content1);
  opacity: 50%;
  height: 50px;
  width: 50px;
  justify-self: end;
  align-items: end;
}
