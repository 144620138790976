
.carouselImage{
    position: absolute;
    left: 0;
    /* width:clamp(320px,100%,435px); */
    width: 100%;
    /* width:435px; */
}

.carouselBlock{
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

.carouselWrapper{
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: rgba(0,0,0,0.2);

    /* padding: 47px 30px 30px 47px ; */



    /* background-size: 100% 100%; */
    transition: filter 0.2s, box-shadow 0.5s;
}

.carouselWrapper:hover{

    
    /* font-weight: bolder; */
    filter: brightness(150%);
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.5);
}

.carouselTitle{
    
    font-weight: 500;
    font-style: bold;
    font-size: 20px;
    letter-spacing: 0.4em;


    position: absolute;
    text-justify: center;
    text-align: center;
    width:100%;
    
    /* color: grey; */
    /* z-index: 3; */
}