.galleryWrapper{
    display: grid;
    grid-auto-flow: row;
    width: 100%;
    /* grid-template-columns: 33% 33% 33%; */
    grid-template-columns: repeat(auto-fill,clamp(300px,32.3vw,400px));
    grid-auto-rows: clamp(min(127vw,420px),44.35vw,565px);
    align-content: start;
    justify-content: center;
    column-gap: max(0.8vw,20px);
    row-gap: max(0.8vw,20px);
    padding: 30px 0 50px;
    /* padding-left: 30px; */
}

.galleryItem{
    position: relative;
    grid-row: auto;
    width: min(90vw,100%);
    height: 100%;
    color: white;
    background-color: grey;
    animation: fadein 1s;
    justify-self: center;
}

@keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}