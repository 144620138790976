.contactMeContentWrapper{
    
    position: absolute;
    height : max(calc(100vh - 500px),300px);
    top : 0;
    width : 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    align-items: center;
    /* overflow: ; */

    
    padding-bottom: 30px;

}

.contactMeContent{
    position: absolute;
    /* height : 80%; */
    top: 0;
    bottom: 0;
    width : 100%;
    display: flex;
    flex-direction: column;

    /* grid-template-columns: max(50%,500px);*/
    row-gap: 10px; 
    

    padding-top: max(calc(50vh - 450px),5px);
    padding-left: 40px;
    padding-right: 40px;
    /* flex-direction: row; */
    align-content: start;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  
  .contactMeText{
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
    font-size: min(16px,3.5vw);
    letter-spacing: 0.2em;
    font-style: bold;
    font-weight: 450;
    color: var(--font-colour-content1);;
  }
  .contactMeEmail{
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
    font-size: 18px;
    color: var(--font-colour-content1);;
  }
  .contactMeSubheading{
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
    font-size: 14px;
    color: var(--font-colour-content1);;
  }