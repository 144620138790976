:root {
  --div-background-r : 5;
  --div-background-g : 5;
  --div-background-b : 16;
  --swiper-navigation-color:#d9dada;
  
  --div-video-edge-color: rgba(var(--div-background-r),var(--div-background-g),var(--div-background-b),1);
  --div-video-mid-color: rgba(var(--div-background-r),var(--div-background-g),var(--div-background-b),0.5);
  --div-video-mid-color: rgba(var(--div-background-r),var(--div-background-g),var(--div-background-b),0.2);
  --div-loading-color: #030417;

  --div-video-ratio: 16 / 9;
  --font-colour-content1: #d9dada;
  --font-colour-content2: #a3a3a3;
  --div-background-dropdown: #040508;
  
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: orange;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin : 0;
  padding: 0;
  font-family: "Montserrat";
  /* src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w9aXx-p7K4KLjztg.woff) format('woff'); */
  font-weight: 400;
  /* color: var(--font-colour-content1); */
}

.swiper-button-prev{
  color: white;
}

.subheading1{
  font-size: 20px;
  font-style: bold;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: var(--font-colour-content1);
}

.subheading2{
  font-size: 25px;
  font-style: bold;
  padding-bottom: 30px;
  letter-spacing: 0.3em;
  
  text-transform: uppercase;
}

.lineHeading1{
  color: #666;
  position: relative;
  font-size: 20px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  letter-spacing: 0.3em;
  width : 100%;
}

.block{
  padding-bottom: 70px;
}

.solidLine{
  color: rgba(8, 1, 25, 0.537);
  position: relative;
  width: 100% ;
  overflow: hidden;
  height: 3px;
  content: '\a0';
  background-color: rgba(128, 128, 128, 0.3);

}

.lineHeading1::before, .lineHeading1::after{
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 48%;
  height: 1px;
  content: '\a0';
  background-color: grey;
}

.lineHeading1::before{
  margin-left: -50%;
  text-align: right;
}

.lineHeading1::after{
  margin-left: 1.5%;
  text-align: right;
}



.paragraph1{
  font-size: 14px;
}

.button1{
  color: rgb(104, 102, 102);
background-color: #ebebeb;
  cursor: pointer;
  font-size: 11px;
padding-top: 8px;
padding-bottom: 8px;
padding-left: 12px;
padding-right: 12px;
border-radius: 0;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
font-weight: 600;
background-image: none;
box-shadow: none;
user-select: none;
position: relative;
border: 1px solid transparent;
box-sizing: border-box;
word-wrap: break-word;
top: 0;
}

.button2{
  color: #666;
background-color: #ebebeb;
  cursor: pointer;
  font-size: 14px;
padding-top: 8px;
padding-bottom: 8px;
padding-left: 12px;
padding-right: 12px;
border-radius: 0;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
background-image: none;
box-shadow: none;
user-select: none;
position: relative;
border: 1px solid transparent;
box-sizing: border-box;
word-wrap: break-word;
top: 0;
}

.button3{
  color: #ebebeb;
  background-color: rgba(255, 255, 255, 0.2);
letter-spacing: 0.25em;
  cursor: pointer;
  font-size: 15px;
padding-top: 0px;
font-weight: 500;
padding-bottom: 0px;
padding-left: 12px;
padding-right: 12px;
border-radius: 5px;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
background-image: none;
box-shadow: none;
user-select: none;
position: relative;
border: 0px solid transparent;
box-sizing: border-box;
word-wrap: break-word;
top: 0;
}

.button4{
  color: #ebebeb;
background-color: rgba(255, 255, 255, 0.2);
letter-spacing: 0.25em;
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 12px;
padding-right: 12px;
border-radius: 5px;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
background-image: none;
box-shadow: none;
user-select: none;
position: relative;
border: 0px solid transparent;
box-sizing: border-box;
word-wrap: break-word;
top: 0;
}

.mainContentWrapper{
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* height: 100%; */
}

.mainContentContainer{
  align-self: center;
  width: 1300px;
  max-width: 90%;
  padding: 50px;

}

.dropdownItem{
  padding: 25px 25px 25px 25px;
  font-size: 20px;
  font-style: bold;
  letter-spacing: 0.4em;
  font-weight: 800;
  text-transform: uppercase;
}


a{
  position: relative;
  cursor: pointer;
  color: inherit;
  transition: brightness(300ms);
}

a:link{
  color: inherit;
  text-decoration: none;
}

a:visited{
  color: inherit;
  text-decoration: none;
}

a:hover{
  filter: brightness(200%);
  color: inherit;
  text-decoration: none;
}

a:active{
  color: inherit;
  text-decoration: none;
}



.window{
  width: 100%;
  height: 100%;
  position: absolute;
}

.background{
  position: absolute;
  top:0;
  width: 100%;
  height: max(100%,800px);
  /* background-color: blue; */
  z-index:0
}

.foreground{
  position: absolute;
  /* overflow-x: hidden; */
  /* overflow: auto; */
  width: 100vw;
  height: max(100%,800px);
  /* background-color: orange; */
  /* z-index: 2; */
}

.contentFont1{
  font-size: 1rem;
}

.main{
  /* width: 100%; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoContainer{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 105%;
  height: 100%;
  left: -2.5%;
  /* background-color: blue; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

/* .audioPlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(black,black);
} */
.hiddenOverflow{
  position: absolute;
  height: 98%;
  transform: translateY(1%);
  width: 100%;
  overflow: hidden;
}

.video {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-55%,-50%);
  min-height: 100vh;
  /* min-height: 99%; */
  min-width: 103vw;
  justify-self: center;
  /* aspect-ratio: var(--div-video-ratio); */
  /* object-fit: contain; */
  /* transition: ; */
}

.videoOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  /* background-color: #282c34; */
  /* background-image: linear-gradient(rgba(0,0,0,0.7),rgba(18, 17, 47, 0.7),rgba(0,0,0,0.7)); */
  /* backdrop-filter: contrast(130%); */
  /* backdrop-filter: hue-rotate(-10deg); */
  backdrop-filter: contrast(130%);
  backdrop-filter: brightness(80%);
  
}

.videoShadow{
  position: absolute;
  left: -8%;
  width: 120%;
  height: 100%;
  /* background-image: linear-gradient(rgb(0,0,0,0.3),rgb(0,0,0,0.6)); */
  /* background-color: (rbg(0,0,0,1)); */
  filter: blur(40vw);
}

.videoEdgeShadow{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient( var(--div-video-edge-color) 5%,var(--div-video-mid-color) 40%,var(--div-video-mid-color),var(--div-video-mid-color)  60%, var(--div-video-edge-color) 95%);
  z-index: 2;
  /* background-color: blue; */
  
  /* filter: blur(200px); */
}


.content {
 /* position: absolute;
  width: 100%;
  height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color: white; */
}
 

.overlay{
  position: absolute;
  width: 130%;
  aspect-ratio: 16 / 9;

  
  background-image: radial-gradient(rgba(0,0,0,0),rgba(0,0,0,1) 70%);
  
}

.blackBackground{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgba(0,0,0,0.2);
}

.colourBackground{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  background-color: var(--div-video-edge-color);
  overflow: auto;
}

.colourOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;

  background-image: linear-gradient(rgba(8,17,27,0.3),rgba(75, 99, 106, 0.4)rgba(8,17,27,0.3))
  /* background-image: linear-gradient(rgba(8,17,27,255),20% rgba(0,0,0,1), 80% rgba(8,17,27,255)); */
}

