.openDropdown{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--div-background-dropdown);
    color: var( --font-colour-content1);
}

.dropdownMenu{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
}



.closeButton{
    position: fixed;
    right: 0;
    top: 0;
    padding: 47px 47px 30px 30px ;
    z-index: 500;
    width: 30px;
    height: 30px;
    color: var(--font-colour-content1);
    cursor: pointer;
    transition: transform 0.3s; 
}