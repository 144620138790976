.mainHeader{
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 500;
}

.webTitleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.webTitle{
    padding: min(55px,10.5vw) 0px 0 min(55px,11vw) ;
    font-weight: 600 !important ;
    font-style: bold;
    font-size: min(25px,5vw);
    letter-spacing: 0.4em;
    color: var(--font-colour-content1);
    cursor: pointer;
}



/* 

.menuDropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 47px 47px 30px 30px ;
    height: 30px;
    width: 30px;
    color: grey;
    transition: filter 300ms; 
}

.menuDropdown{
    filter: brightness(120%);
} */


/* .menuDropdown::hover {
    filter: brightness(1.9);
} */

/* .menuDropdownContainer:hover {
    backdrop-filter: brightness(130%); */
/* } */