.container{
    width: 100%;
}

.play{
    width: 40%; 
    height: 100%
}

 .pause{
    width: 40%;
    height: 100% 
 }

 .skipBack{
    width: 30%;
    height: 70% 
 }

 .skipForward{
    width: 30%; 
    height: 70%
 }

 