


.audioPlayer{
    position: absolute; 
    /* top : 0; */
    width: 86%;
    height: 100%;
    left: 7%;
/* 
    max-height: 100%;
    top : max(50%,250px);
    transform: translateY(-50%); */
    /* top : max(80px); */
    color: var(--font-colour-content1);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    z-index: 3;
    justify-self: center;
    align-self: center;
  }



  .trackListContainer{
    width: min(100%,400px);
    min-height: 140px;

    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-items: center;

  }
  
  .trackList{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    /* align-self: center; */
    /* align-self: center; */
  }

  .trackTitle{
    display: flex;
    justify-content: center;
    justify-content: center;
    /* height: 65px; */
    /* margin-top: 0px; */
    /* white-space: nowrap; */
  }

  .trackTitleText{
    font-size: min(20px);
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: bold;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: var(--font-colour-content1);
  }

  .waveformParent{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;

  }
  
  .waveformContainer{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  }
  .waveform{
    width:calc(100% - max(10% , 120px));
    height: 100px;
    justify-content: center;
  }
  
  .waveformPosition{
    display: flex;
    justify-content: center;
    text-align: center;
    text-justify:auto;
    width:max(5%,60px);
  }
  
  
  .waveformRemainingDuration{
    display: flex;
    justify-content: center;
    text-align: center;
    text-justify:auto;
    width:max(5%,60px);
  }
  

.playerControls{
    align-self: center;
    display: flex;
    flex-direction: row;
    height : 50px;
    width : 150px;
}

