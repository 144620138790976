.menuDropdown{
    position: fixed;
    right: 0;
    top: 0;
    padding: min(55px,9vw) min(55px,10vw) 40px 40px ;
    z-index: 500;
    width: 30px;
    height: 30px;
    color: var(--font-colour-content1);
    cursor: pointer;
    transition: transform 0.3s; 
    
}

.menuDropdown:hover {
    transform: scale(1,0.8);
}

