
  .trackContainer{
    /* position: relative; */
    width: 100%;
    height: 22px;
    display: flex;
    font-size: 14px;
    /* flex: 1, 1,  1; */
    flex-direction: row;
    text-transform: capitalize;
    text-overflow: ellipsis;
    /* justify-content: center; */
    /* align-items: center; */
  }

.trackPlayButton{
    width: 15%
  }

  .trackNameWrapper{
    width: calc(78%);
    height: 18px;
    overflow: hidden;
    text-overflow:ellipsis;
    padding-right: 2%;
  }
  
  .trackName{
    text-align: left;
    text-overflow: ellipsis;
    font-size: min(14px,4vw);
    font-weight: 500;
  }

  .trackNameSelected{
    text-overflow: ellipsis;
    text-align: left;
    font-size: min(14px,4vw);
    font-weight: 700;
  }


  
  .trackName:hover{
    cursor: pointer
  }

  .trackNameSelected:hover{
    cursor: pointer
  }


  .trackDescription{
    color: var(--font-colour-content2);
    
    text-overflow: ellipsis;
    font-size: min(12.5px,3.8vw);
  }
  .trackDescriptionSelected{
    color: var(--font-colour-content2);
    text-overflow: ellipsis;
    font-size: min(12.5px,3.8vw);
    font-weight: 600;
  }

  .trackDescription:hover{
    cursor: pointer
  }

  .trackDescriptionSelected:hover{
    cursor: pointer
  }

  .trackNumber{
    width: 6%;
    align-items: left;
    text-align: left;
    padding-left: 10px;
    
  }

  .trackNumberSelected{
    width: 6%;
    align-items: left;
    text-align: left;
    padding-left: 10px;
    font-weight: 700;
  }

  .trackNumber:hover{
    cursor: pointer
  }

  .trackNumberSelected:hover{
    cursor: pointer
  }
  
  .trackLength{
    width: 10%
  }

  .trackLengthSelected{
    width: 10%;
    font-weight: 700;
  }

  .trackLength:hover{
    cursor: pointer
  }

  .trackLengthSelected:hover{
    cursor: pointer
  }