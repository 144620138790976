.aboutMeContentWrapper{
    
    position: relative;
    /* height : calc(100vh - 400px); */
    min-height: calc(100vh - 380px);
    /* height: 80vh; */
    top : 0;
    width : min(80%,1300px);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    row-gap: 50px;
    align-items: center;
    /* overflow: ; */

    padding-top: min(30px,2vw);
    
    padding-bottom: min(30px,2vw);

}

.aboutMeContent{
    position: relative;
    /* height : 80%; */
    min-height: calc(100vh - 380px);
    top: 0;
    bottom: 0;
    width : 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill,max(50%,320px));
    grid-row-gap: 50px;
    /* padding-top: max(calc(40vh - 450px), 10px); */
    padding-left: 20px;
    padding-right: 20px;
    /* flex-direction: row; */
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    
  }

  .headshot{
    width : min(450px,80%);

  }
  

  .aboutMeText{
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    font-size: 13px;
    color: var(--font-colour-content1);
    width: min(100%,80vw);
    line-height: 1.6;
  }

  .portfolioClickThroughContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 30px;
    height: 100%;
    width: 100%;
    font-size: 25px;
  }