

.videoFrameMain{
    position: absolute;
    width: 106vw;
    height: max(80vh,600px);
    left: -2.6%;
    /* height: 1000px; */
    /* padding-top: 90px; */
    /* top: 180px; */
    /* aspect-ratio: 16 / 9; */
    overflow: hidden;
    vertical-align: middle;
}
