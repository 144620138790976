.carousel{
    height: clamp(200px,20vw,255px);
    padding-bottom: 40px;
}

.carouselImage{
    position: absolute;
    left: 0;
    width:clamp(320px,100%,435px);
    /* width:435px; */
}

.carouselWrapper{
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-items: center;
    align-items: center;
    transition: filter 0.2s, box-shadow 0.3s;
}

.carouselWrapper:hover{
    filter: brightness(150%);
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.5);
}

.carouselTitle{
    position: absolute;
    text-justify: center;
    text-align: center;
    width:100%;
    font-size:25px;
    /* color: grey; */
    /* z-index: 3; */
}