.projectGridContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* background-image: linear-gradient(var(--bg-color-aqua) 0%,var(--bg-color-deep-blueblack) 30%,var(--bg-color-deep-blueblack) 80%,var(--bg-color-deep-blueblack)); */
    
}

.projectsBackgroundWrapper{
    position: absolute;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    width: 100%;
    /* justify-content: center; */
    /* align-content: center; */
    /* align-items: center; */
    background-image: linear-gradient(var(--bg-color-aqua) 0%,var(--bg-color-deep-blueblack) 30%,var(--bg-color-deep-blueblack) 80%,var(--bg-color-deep-blueblack));
    
}


.projectGrid{
    position: relative;
    /* height: 100%; */
    top : 0;
    overflow: hidden;
    width: 100%;
    /* width: min(86%,1300px); */
    padding: min(3vw,30px) 40px 50px 40px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    row-gap: min(1.5vw,15px) ;
   

}

.imagePlayerCombiFrame{
    position: relative;
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: min(1vw,10px) ;
    height: max(min(40vw,600px),470px);
    justify-content: center;
    /* overflow: hidden; */
    /* aspect-ratio: 16 / 7;
    overflow: hidden;
    vertical-align: middle; */
}

.frame{
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    vertical-align: middle;
}

.videoFrame{
    position: absolute;
    width: 105vw;
    height: max(min(55vh,800px),550px);
    /* transform: translateY(); */
    /* left: -2.5%; */
    /* height: 1000px; */
    /* padding-top: 90px; */
    /* top: 180px; */
    /* aspect-ratio: 16 / 9; */
    /* overflow: hidden; */
    vertical-align: middle;
}

.playerFrame{
    position: absolute;
    padding-top: min(2.5vh,10px);
    transform: translateY(-3vh);
    width: 100%;
    height: 448px;
    overflow: hidden;
    /* transform: translateY(-55%); */
    /* overflow: hidden; */
    /* vertical-align: middle; */
}

.player{
    /* transform: translateY(40%); */
    top:-10%;
    /* padding-bottom: 5px; */
}

.projectImage{
    position: relative;
    /* background-color: grey; */
    width: 107%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.mainBodyContainer{
    position: relative;
    display: flex;
    z-index: 1;
    /* width: 100%; */
    width: min(86%,1300px);
    row-gap: min(4.5vw,30px);
    color: var(--font-colour-content1);
    flex-direction: column;
    /* padding-top: 2vw; */
    /* padding-bottom: 2vw; */
}

.projectTitleWrapper{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 70px;
    align-content: center;
    
}

.arrow{
    height: 100%;
    width: 15%;
    /* width: 40px; */
    /* aspect-ratio: 1/1; */
}

.projectTitle{
    height: 100%;
    width: 100%;
    font-size: min(30px,3.5vw);
    text-align: center;
    text-justify: center;
    align-content: center;
    font-weight: 500;
    letter-spacing: 0.2em;
    /* padding-bottom: 0px; */
    
}

.navTitle{
    height: 100%;
    width: 100%;
    font-size: min(20px,3.5vw);
    text-align: center;
    text-justify: center;
    align-content: center;
    font-weight: 500;
    letter-spacing: 0.2em;
    /* padding-bottom: 0px; */

    padding-top: min(20px,4vw);
    
}

.directedBy{
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.15em;
    filter: brightness(80%);
}

.projectText{
    text-align: justify;
    font-size: 14px;
    line-height: 1.6;
    height: 100%;
    /* padding-bottom: min(0.5vw,8px); */
}

.supplementaryImageContainer{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    aspect-ratio: 32 / 9;
    /* background-color: brown; */
}

.navigationButtons{
    display: flex;
    flex-direction: horizontal;
    width: 100%;
    height: 60px;
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.centreButtonWrapper{
    position: relative;
    width: 100%;
    /* width: 100%; */
    padding-top: 10px;
    /* padding-right: 30px; */
    padding-bottom: 10px;
    /* position: relative; */
    display: flex;
    justify-content: center;
}

.leftButtonWrapper{
    position: relative;
    width: 20%;
    /* width: 100%; */
    padding-top: min(10px,3vw);
    padding-left: min(10px,3vw);
    padding-bottom: min(10px,3vw);
    /* position: relative; */
    display: flex;
    justify-content: left;
}

.rightButtonWrapper{
    position: relative;
    width: 20%;
    /* width: 100%; */
    padding-top: min(10px,3vw);
    padding-right: min(10px,3vw);
    padding-bottom: min(10px,3vw);
    /* position: relative; */
    display: flex;
    justify-content: right;
}

.navButton{
    color: #ebebeb;
    background-color: rgba(255, 255, 255, 0.2);
  letter-spacing: 0.25em;
    cursor: pointer;
    font-size: min(15px,3vw);
  padding-top: 0px;
  font-weight: 500;
  padding-bottom: 0px;
  padding-left: min(12px,3vw);
  padding-right: min(12px,3vw);
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  box-shadow: none;
  user-select: none;
  position: relative;
  border: 0px solid transparent;
  box-sizing: border-box;
  word-wrap: break-word;
  top: 0;
  }