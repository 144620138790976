.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 800ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 800ms;
  }


  .loading-enter {
    opacity: 1;
  }
  .loading-active {
    opacity: 1;
    /* transition: opacity 0ms; */
  }
  .loading-exit {
    opacity: 1;
  }
  .loading-exit-active {
    opacity: 0;
    transition: opacity 900ms;
  }